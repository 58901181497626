import axios from "axios";
import { getVoiceApi } from "./config.service";

const getToken = () => {
    if (localStorage.getItem('TOKEN')) {

        return {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
    }

    return {};
}

export const loginService = axios.create({
    baseURL: getVoiceApi(),
    timeout: 30000,
});

export default axios.create({
    baseURL: getVoiceApi(),
    timeout: 30000,
    ...getToken(),
});


export const ssoApi = axios.create({
    baseURL: getVoiceApi()?.replace('/voice/v1', ''),
    timeout: 30000,
});




export const acApi = axios.create({
    baseURL: `https://api.tekvision.com`,
    timeout: 30000,
});