import { Box, Button, Chip, MenuItem, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import ContentHeader from "../mui/ContentHeader";
import FilterComponent from "../mui/FilterComponent";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import CustomFooter from "../mui/CustomFooter";
import { useDispatch, useSelector } from "react-redux";
import { getAlerts } from "../../actions";
import { formatDate, formatDateTime } from "../../helper/date";
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";

const AlertList = () => {
    const dispatch = useDispatch();
    const [closeFilter, setCloseFilter] = useState(false);
    const apiRef = useGridApiRef();
    const [rowPerPage, setRowPerPage] = useState(10);
    const [page, setPage] = useState(1);
    let history = useHistory();
    const alerts = useSelector(state => state.reportReducer.alerts);
    const statuses = useSelector(state => state.reportReducer.alertStatuses);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [fullTextSearch, setFullTextSearch] = useState('');
    const {t} = useTranslation();

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const formatToLocalTime = (dateString) => {
        console.log(dateString);
        const date = new Date(dateString + ' UTC'); // Adding ' UTC' ensures the date is treated as UTC
        // Options for date formatting (using Toronto's time zone)
        const options = {
            timeZone: 'America/Toronto',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // 24-hour format
        };

        // Format date according to Toronto's time zone
        const localDate = new Intl.DateTimeFormat('en-CA', options).format(date);
        console.log(localDate);
        return localDate;
      };

    const columns = [
        { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true },
        {
            field: "testCaseName",
            headerName: t("testCaseName.1"),
            width: 200,
            disableColumnMenu: true,
            renderCell: params => (
                <div onClick={() => history.push(`/alert/${params.row.id}`)}>{params.row.testCaseName}</div>

            )

        },
        {
            field: "alertTime",
            headerName: t("dateTime.1"),
            flex: 1,
            disableColumnMenu: true,
            valueFormatter: (params) => formatToLocalTime(params.value),
        },
        {
            field: "distributionName",
            headerName: t("distributionList.1"),
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "testRunCount",
            headerName: t("testRunCount"),
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: t("detailedStatus"),
            flex: 1,
            disableColumnMenu: true,

        },
    ];

    const Filter = () => {

        return (
            <div>
                <Typography variant="subtitle1">
                    <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
                </Typography>
                <Box sx={{ display: 'flex', marginTop: 1 }}>

                    <TextField select size="small"
                        label="Select Timeframe" sx={{ width: 180 }}>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                    </TextField>

                    <TextField select size="small"
                        label="Select Timeframe" sx={{ width: 200, ml: 3 }}>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                        <MenuItem></MenuItem>
                    </TextField>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="subtitle1">
                        <div style={{ fontSize: 14 }}>{t("status")} &nbsp;&nbsp;
                            <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {selectedStatus.length > 0 ? `${selectedStatus.length} SELECTED` : ''} </span>
                        </div>

                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '400px', marginTop: 1 }}>
                        {[{ name: t("failure.0"), id: 7 }, { name: t("pass.0"), id: 3 }, { name: t("warning.0"), id: 4 }].map((value, index) => (
                            <Chip key={index} label={value.name}
                                sx={{
                                    fontWeight: "bold",
                                    background: selectedStatus.find(d => d.id === value.id) ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                    borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                    cursor: 'pointer'
                                }} onClick={() => handleChangeType(value)} />
                        )
                        )}
                    </Box>
                </Box>
                <Box textAlign='left'
                    sx={{
                        width: "100%",
                        display: 'flex',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        sx={{ mt: 1, mb: 5, ml: 2 }}
                        style={{
                            borderRadius: 20,
                            background: '#3F929E1A 0% 0% no-repeat padding-box',
                            height: 38,
                            width: '93px',
                            color: '#1F9997',

                        }}
                        onClick={reset}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "#1f9997" }}>{t("reset")}</Typography>
                    </Button>

                    <Button
                        sx={{ mt: 1, mb: 5, mr: 2 }}
                        style={{
                            height: 40,
                            borderRadius: 20,
                            width: 135,
                            background: '#1F9997 0% 0% no-repeat padding-box',
                        }}
                        onClick={applyFilters}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "white" }}>{t("applyFilters")}</Typography>
                    </Button>
                </Box>
            </div>
        )
    }

    const reset = () => {
        setSelectedStatus([]);
        setFilterStatus([]);
    }

    const applyFilters = () => {
        setFilterStatus(selectedStatus.map(c => c.id));
        setCloseFilter(new Date().getTime());
    }

    const handleChangeType = (type) => {
        if (!selectedStatus.find(s => s.id === type.id)) {
            setSelectedStatus([...selectedStatus, type])
        } else {
            setSelectedStatus(selectedStatus.filter(s => s.id !== type.id));
        }
    };

    const rows = useMemo(() =>
        (alerts?.content || []).filter(d => d?.testCaseName?.toLowerCase().indexOf(fullTextSearch) !== -1 ||
            d?.distributionName?.toLowerCase().indexOf(fullTextSearch) !== -1).map(item => ({
                ...item,
                id: item.alertId,
                runStatusId: +item.testRuns[0]?.runStatusId,
                status: statuses.find(s => s.id === +item.testRuns[0]?.runStatusId)?.text,
                alertTime: formatDateTime(item.alertTime, 'yyyy/MM/dd HH:mm:ss')
            })), [alerts, fullTextSearch]);

    const setSearchText = (input) => {
        setFullTextSearch(input);
    }

    useEffect(() => {
        getAlerts(page, rowPerPage)(dispatch);
    }, [page, rowPerPage]);

    return (
        <div style={isMobile ? { paddingBottom: '65px', paddingLeft: '24px', paddingRight: '24px', paddingTop: '0' } : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={t("alerts.1")} />
            <Box textAlign='left'
                sx={{
                    minWidth: isMobile ? "100%" : "500px",
                    borderColor: 'lightgrey',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#1F9997',
                    marginBottom: '5px',
                    verticalAlign: 'middle'
                }}
            >
                <Typography type="body2" className="edit-title">
                    {t("alerts.0")}
                </Typography>

            </Box>
            <Box sx={{ mt: 3 }}>
                <FilterComponent
                    setSearchText={setSearchText}
                    closeDialog={closeFilter} count={alerts?.totalElements} title={t("alerts.0")} filter={false} refresh={true}
                    filterComponent={(<Filter />)}></FilterComponent>
            </Box>
            <Box sx={{ mt: 2, height: "550px", width: '100%' }}>
                {isMobile ? rows.map((item, index) => (
                    <Accordion key={`row_${item.id}`} className={`row_${index % 2}`} elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="db w-full">
                                <Typography className="underscore pointer w-full inline-block semi-bold" variant="caption"
                                ><span onClick={() => history.push(`/alert/${item.id}`)}>{item.testCaseName}</span></Typography>
                                <Typography className="w-full inline-block semi-bold" variant="caption">{item.alertTime}</Typography>
                                <Typography className="w-full inline-block semi-bold" variant="caption"><span className={item.status}>{item.status}</span></Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="db w-full">
                                <Typography className="w-full inline-block" variant="caption">{t("durationList")}: {item.distributionName}</Typography>
                                <Typography className="w-full inline-block" variant="caption">{t("NoOfTestRunsInAlert")}:{item.testRunCount}</Typography>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )) : (
                    <DataGrid rows={rows} columns={columns}
                        apiRef={apiRef}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        pageSize={rowPerPage}
                        page={page - 1}
                        sortingOrder={['desc', 'asc']}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'alertTime', sort: 'desc' }],
                            },
                        }}
                        components={{
                            Footer: () => CustomFooter(page, handlePageChange, alerts?.totalElements || 0, rowPerPage, setRowPerPage, setPage)
                        }}
                        getCellClassName={(params) => {
                            if (params.field === "testCaseName") {
                                return 'underscore pointer'
                            } else if (params.field === "status") {
                                return params.formattedValue;
                            }

                        }}

                    />
                )}

            </Box>
        </div>
    )

}

export default AlertList;
