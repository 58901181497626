import { Box, Typography, Grid, TableHead, Table, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LeftBar from "../../dashboard/LeftBar";
import Navbar from "../../dashboard/Navbar";
import ContentHeader from "../../mui/ContentHeader";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile, downloadVoice, getTestRunDetails } from "../../../actions";
import { formatDate, formatDateTime } from "../../../helper/date";
import downloadIcon from "../../../images/Download Icon.svg";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { AudioPlayer } from "../../mui/AudioPlayer";
import { Audio} from "../../mui/Audio";


const TestRunResult = () => {
    const defaultPage = [0, 1, 0, 0];
    const dispatch = useDispatch();
    const { id, latency } = useParams();
    const testRunDetails = useSelector(state => state.reportReducer.testRunDetails);
    const statuses = useSelector(state => state.reportReducer.alertStatuses);
    const { t } = useTranslation();

    const formatOutput = (run, columns) => {
        let output = columns;
        Object.keys(run).forEach(key => {
            if (columns.indexOf(key) !== -1) {
                output = `${output.replace(key, run[key])} `;
            }
        });

        return output;
    }
console.log('testRunDetails',testRunDetails);

    // const getAudio = useMemo(() => flatten(testRunDetails?.segmentRuns.map(seg => seg.stepRuns.filter(r => r.recordingId).map(sr => ({
    //     ...sr,
    //     title: seg.segmentName,
    //     runId: seg.runId,
    // })))), [testRunDetails]);

    useEffect(() => {
        if (id) {
            getTestRunDetails(+id)(dispatch);
        }
    }, [id]);

    return (
        <div>
            <Navbar />
            <Grid container spacing={3} style={isMobile ? { padding: '16px' } : {}}>
                {!isMobile && (
                    <Grid item>
                        <LeftBar page={defaultPage} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div style={isMobile ? {} : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
                        <ContentHeader title={t("testRunDetails.1")} />

                        {/* {(audioPlayerVisible() && getAudio?.length > 0) && (
                            <div className="flex">
                                {(getAudio || []).map((run, index) => (
                                    <div className="flex-item">
                                        <AudioPlayer url={{ id: run.recordingId, url: 'recordingfiles', title: run.title, runId: run.runId, index: index + 1, total: getAudio.length }} />
                                    </div>
                                ))}
                            </div>
                        )} */}
                        {(testRunDetails?.segmentRuns?.length || testRunDetails?.segments?.length) && (
                            <AudioPlayer times={testRunDetails.segmentRuns || testRunDetails?.segments} url={{ id: testRunDetails.runId || testRunDetails?.testResult?.id, url: 'wcr', title: testRunDetails.testCaseName || testRunDetails.name, runId: testRunDetails.runId || testRunDetails?.testResult?.id, index: 1, total: 1 }} />
                        )}
                        <Box textAlign='left'
                            sx={{
                                minWidth: isMobile ? "100%" : "500px",
                                borderColor: 'lightgrey',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: '#1F9997',
                                marginBottom: '5px',
                                verticalAlign: 'middle'
                            }}
                        >
                            <Typography type="body2" className="edit-title">
                                {t("testRunDetails.0")}
                            </Typography>

                        </Box>

                        <Box sx={{ mt: 5, ml: 3 }}>
                            <Grid container className={isMobile ? "mobile-alert-settings" : ''}>
                                <Grid xs={isMobile ? 12 : (latency ? 4 : 6)}>
                                    <Typography variant="h7">{latency ? t("testSegmentID") : t("testCaseID")}:</Typography>
                                    <Typography variant="h8">#{testRunDetails?.testCaseId || testRunDetails?.id}</Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : (latency ? 4 : 6)}>
                                    <Typography variant="h7">{latency ? t("testSegmentName.0") : t("testCaseName.0")}:</Typography>
                                    <Typography variant="h8">{testRunDetails?.testCaseName || testRunDetails?.name}</Typography>
                                </Grid>
                                {latency && (
                                    <Grid xs={latency ? 4 : 6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="h7">{t("testRunStatus")}:</Typography>
                                        <Typography variant="h8">{statuses?.find(s => s.id === testRunDetails?.runStatusId)?.text}</Typography>
                                    </Grid>
                                )}
                                <Grid xs={isMobile ? 12 : (latency ? 4 : 6)} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{latency ? t("latencyFor") : t("testRunID")}:</Typography>
                                    <Typography variant="h8">{latency ? latency.replace('undefined', '') : testRunDetails?.runId || testRunDetails?.testResult?.id}</Typography>
                                </Grid>
                                <Grid xs={latency ? 4 : 6} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("dateTime.0")}:</Typography>
                                    <Typography variant="h8">{latency ? formatDateTime(testRunDetails?.runTime || testRunDetails?.testResult?.startTime) : formatDateTime(testRunDetails?.runTime || testRunDetails?.testResult?.startTime)}</Typography>
                                </Grid>
                                {latency && (
                                    <Grid xs={latency ? 4 : 6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="h7">{t("testRunID")}:</Typography>
                                        <Typography variant="h8">{testRunDetails?.testResult?.id || testRunDetails?.runId}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>

                        <Box sx={{ mb: 4 }} style={{ overflow: 'auto' }}>
                            {(testRunDetails?.segmentRuns || testRunDetails?.segments)?.map(segments => (
                                <div className={"db  border-run " + (isMobile ? "mobile-alert-settings" : '')}>
                                    <div className="db header-run">
                                        <Typography variant="h7">{t("testSegment")}:</Typography>
                                        <Typography variant="h8">{segments?.runId || segments?.testResult?.id} - {segments?.segmentName || segments?.name}</Typography>
                                    </div>
                                    <Table className="step-run-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '5%' }}>{t("stepId")}</TableCell>
                                                <TableCell sx={{ width: '10%' }}>{t("stepType")}</TableCell>
                                                <TableCell sx={{ width: '50%' }}>{t("details.1")}</TableCell>
                                                <TableCell sx={{ width: '10%' }}>{t("detailedStatus")}</TableCell>
                                                <TableCell sx={{ width: '10%' }}>{t("promptLatency")}</TableCell>
                                                <TableCell sx={{ width: '10%' }}>{t(testRunDetails?.segments?.length ? "Duration":"utterance")}</TableCell>
                                                <TableCell sx={{ width: '5%' }}>ACTIONS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(segments.stepRuns || segments.steps).map(run => (
                                                <TableRow key={run.runId || run?.testResult?.id}>
                                                    <TableCell >{run.stepId || run.stepSeq}</TableCell>
                                                    <TableCell>[{run.typeDisplayText || run.stepType}]</TableCell>
                                                    <TableCell>
                                                        {run?.ui_detail_column?.map(col => (
                                                            <div className="db mb-2">
                                                                {formatOutput(run, col)}
                                                            </div>
                                                        ))}

                                                        {run?.stepType?.toLowerCase() === "listen" && (
                                                            <>
                                                                <div>{run?.prompt}</div>
                                                                <div style={{ marginTop: '20px' }}>
                                                                    [{run?.promptLatencyMin},{run?.promptLatencyMax}] {(run?.testResult?.voice?.utterance) ? (run?.testResult?.voice?.utterance) : '<Utterance not recognized>'} [{run?.incompleteTimeout},{run?.completeTimeout}]
                                                                </div>
                                                            </>
                                                        )}

                                                        {run?.stepType?.toLowerCase() === "listen anything" && (
                                                            <>
                                                                <div>{run?.prompt}</div>
                                                                <div style={{ marginTop: '20px' }}>
                                                                    [{run?.promptLatencyMin},{run?.promptLatencyMax}] {run?.testResult?.voice?.utterance} [{run?.incompleteTimeout},{run?.completeTimeout}]
                                                                </div>
                                                            </>
                                                        )}

                                                        
                                                        {run?.stepType?.toLowerCase() === "play audio" && (
                                                            <>
                                                                {/* Render Play Audio-specific content here */}
                                                                <div>
                                                                    {run?.libraryItemIds.map((item, index) => (
                                                                        <div key={index}>{item.name}</div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )}


                                                        {run?.stepType?.toLowerCase() === "record audio" && (
                                                            <>
                                                                {/* Render Record Audio-specific content here */}
                                                                <div>{run?.recordingHint}</div>
                                                            </>
                                                        )}

                                                        {run?.stepType?.toLowerCase() === "speak" && (
                                                            <>
                                                                <div>{run?.messageToPlay}</div>
                                                            </>
                                                        )}

                                                        {run?.stepType?.toLowerCase() === "va - transfer call to agent" && (
                                                            <>
                                                                {/* Render va - transfer call to agent-specific content here */}
                                                                <div>{run?.agentXferActionQueueName}</div>
                                                                <div>{run?.agentXferActionAgentID}</div>
                                                            </>
                                                        )}

                                                        {run?.stepType?.toLowerCase() === "va - mouse action" && (
                                                            <>
                                                                {/* Render va - mouse action-specific content here */}
                                                                <div>{run?.mouseControlActionElementName}</div>
                                                            </>
                                                        )}


                                            {run?.stepType?.toLowerCase() === "va - get call data" && (
                                                <>
                                                    {/* Render va - get call data-specific content here */}
                                                    <div>
                                                        {Object.keys(run?.participantDataActionAttributeNames || {}).map((key) => (
                                                            <div key={key}>
                                                                <strong>{key}:</strong> {run.participantDataActionAttributeNames[key]}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                            {run?.stepType?.toLowerCase() === "va - get screen data" && (
                                                <>
                                                    {/* Render va - get screen data-specific content here */}
                                                    <div>{run?.testResult?.voice?.agentResponse?.text || run?.screenDataActionExpectedValue}</div>
                                                </>
                                            )}

                                            {run?.stepType?.toLowerCase() === "va - enter screen data" && (
                                                <>
                                                    {/* Render va - enter screen data-specific content here */}
                                                    <div>{run?.fillScreenDataActionValue}</div>
                                                </>
                                            )}
                                                    </TableCell>
                                                    <TableCell className={run?.testResult || run.stepType ? (run?.testResult?.status || 'NOT RUN'):statuses?.find(s => s.id === +run.runStatus)?.text}>
                                                        {run?.testResult || run.stepType ? (run?.testResult?.status || 'NOT RUN'): statuses?.find(s => s.id === +run.runStatus)?.text}
                                                    </TableCell>
                                                    <TableCell>{run?.testResult || run.stepType ? (((run?.testResult?.voice?.latency || run?.promptLatencyMax || 0))/1000).toFixed(2) :(!run.bargeInTime ? '' : (+run.bargeInTime)?.toFixed(1))}</TableCell>
                                                    <TableCell>
                                                        {!run.recordingDuration ? '' : (+run.recordingDuration)?.toFixed(2) + ' sec'} <br />{run.confidence}
                                                        
                                                        {run?.stepType?.toLowerCase() === "listen" && (
                                                            <>
                                                                {run?.testResult?.voice?.recordingDuration ? (run?.testResult?.voice.recordingDuration / 1000).toFixed(2) + ' sec'
                                                                    : '0 sec'}
                                                                <br />
                                                                {run?.testResult?.voice?.confidence?.toFixed(2)  || run?.promptConfidenceLevel.toFixed(2) || '--'}
                                                            </>
                                                        )}
                                                        {run?.stepType?.toLowerCase() === "va - transfer call to agent" && (
                                                            <>
                                                                {run?.testResult?.voice?.latency ? (run?.testResult?.voice?.latency / 1000).toFixed(2) + ' sec'
                                                                    : '0 sec'}
                                                            </>
                                                        )}
                                                        {["pause", "listen anything", "play audio", "record audio", "speak", "va - screenshot", "va - mouse action", "va - get call data", "va - get screen data", "va - enter screen data"].includes(run?.stepType?.toLowerCase()) && (
                                                            <>
                                                                {(run?.testResult?.startTime && run?.testResult?.endTime) ? (
                                                                    (
                                                                        (new Date(run.testResult.endTime).getTime() - new Date(run.testResult.startTime).getTime()) / 1000
                                                                    ).toFixed(2) + ' sec'
                                                                ) : '0 sec'}
                                                            </>
                                                        )}
                                                        
                                                        </TableCell>
                                                    <TableCell style={{display:'flex',gap:'8px'}}>
                                                    {["listen", "listen anything", "play audio", "record audio", "speak"].includes(
                                                                run?.stepType?.toLowerCase()
                                                            ) && (
                                                                    <Audio url={run?.testResult?.attachmentUrl}></Audio>
                                                               
                                                                )}
                                                        {(run?.testResult?.attachmentUrl || run.recordingId) && (
                                                            <img onClick={() => run?.testResult?.attachmentUrl ? downloadVoice(run?.testResult?.attachmentUrl): downloadFile(run.recordingId, 'recordingfiles')} className="pointer" src={downloadIcon} alt="Download" />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </div>
                            ))}
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default TestRunResult;
