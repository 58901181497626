import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
    Button,
    CssBaseline,
    IconButton, InputAdornment, SvgIcon, TextField
} from "@mui/material";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import ResponsiveDialog from "../../mui/ResponsiveDialog";
import { useHistory } from "react-router-dom";
import refresh from "../../../images/Refresh Data.svg";
import trashCan from "../../../images/Delete Action Icon Component.svg";
import edit from "../../../images/Edit Action Icon Component.svg";
import view from "../../../images/View Action Icon Component.svg"
import Box from "@mui/material/Box";
import CustomFooter from "../../mui/CustomFooter";
import { useDispatch, useSelector } from "react-redux";
import { deleteReports, downloadCSV, isUserReadonly } from "../../../actions";
import Filter from "./Filter";
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import moment from "moment";
import filterIcon from "../../../images/filter.svg";
import { useMemo } from "react";
import { formatDateTime } from "../../../helper/date";
import { useTranslation } from "react-i18next";
import download from "../../../images/download.svg";

const useStyles = makeStyles((theme) => ({

    container: {
        paddingTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        justifyContent: "space-around"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    toolbar: {
        marginBottom: theme.spacing(2),
        [`& fieldset`]: {
            borderRadius: 35,
            borderColor: '#EEEEEE',
        },

        '& input': {
            fontSize: '1rem',
            lineHeight: '19px',
            fontFamily: "Montserrat",
            fontWeight: 400,
        },

        display: "flex",
        alignItems: "center"
    },
    content: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },

}));
export default function ContentDetail() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const apiRef = useGridApiRef()
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(1)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [selected, setSelected] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [hash, setHash] = useState("init");
    const [deleteButton, setDeleteButton] = useState(true)
    const [deleteId, setDeleteId] = useState('')
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterOption, setFilterOption] = React.useState([]);
    const [fullTextSearch, setFullTextSearch] = React.useState('');
    const reports = useSelector(state => state.reportReducer.getReports)
    const createReport = useSelector(state => state.reportReducer.createReport)
    const { t } = useTranslation();
    const writeAccess = !isUserReadonly();

    const filterHandleClose = value => {
        setFilterOpen(false);
    };
    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const searchHandler = debounce((searchVal) => {
        setSearchQuery(searchVal)
    }, 500)

    const handleDelete = (id) => {
        setOpen(true);
        setHash(Math.random().toString(16).substring(2, 10));
        setDeleteId(id)
    }

    const downloadReport = async (id) => {
        const data = await downloadCSV(id)(dispatch);

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += data.data;

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", data.file);
        document.body.appendChild(link); 

        link.click();

    }

    useEffect(() => {
        if (selected.length > 0) {
            setDeleteButton(false)
        } else {
            setDeleteButton(true)
        }
    }, [selected])

    const handleRead = (id) => {
        document.location.href = (`/report/view/${id}`)
    }
    const handleEdit = (id) => {
        history.push(`/report/edit/${id}`)
    }

    const add = () => {
        history.push(`/report/add`)
    }

    const columns = [
        { field: "id", headerName: "ID", width: 83, disableColumnMenu: true },
        {
            field: "report_label",
            headerName: t("reportLabel.0"),
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "report_type",
            headerName: t("reportType.1"),
            flex: 1,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                let tempType = null
                if (cellValues) {
                    tempType = cellValues.value.split('\n')
                }

                return (
                    <div>
                        <div style={{ marginBottom: "10px" }}>{tempType[0]}</div>
                        {tempType[1] && <div>{tempType[1]}</div>}
                        {tempType[2] && <div>{tempType[2]}</div>}
                    </div>
                );
            }
        },
        {
            field: "test_case_group",
            headerName: t("testCaseGroup.1"),
            width: "160",
            disableColumnMenu: true,
        },
        {
            field: "time_frame",
            headerName: t("timeFrame.1"),
            flex: 0.7,
            disableColumnMenu: true,
        },
        {
            field: "start_date",
            headerName: t("startDate.1"),
            disableColumnMenu: true,
            width: 130,
        },
        {
            field: "end_date",
            headerName: t("endDate.1"),
            disableColumnMenu: true,
            width: 115,
        },

        {
            field: "last_modified",
            headerName: t("lastModified"),
            disableColumnMenu: true,
            width: 180,
        },

        {
            field: "actions",
            headerName: 'ACTIONS',
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            width: 140,
            sortable: false,
            disableColumnMenu: true,
            renderCell: params => (
                <div style={{ position: 'relative', width: '100%' }}>
                    <div>
                        <IconButton component="span" size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRead(params.row.id)
                            }
                            }
                        >
                            <img src={view} alt="pic" width="30px" />
                        </IconButton>

                        {writeAccess && <IconButton component="span" size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleEdit(params.row.id)
                            }
                            }
                        >
                            <img src={edit} alt="pic" width="30px" />
                        </IconButton>}

                        <IconButton component="span" size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadReport(params.row.id)
                            }
                            }
                        >
                            <img src={download} alt="pic" width="23px" />
                        </IconButton>

                        {writeAccess && <IconButton color="error" component="span" size="small" onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(params.row.id)
                        }}>
                            <img src={trashCan} alt="pic" width="30px" />
                        </IconButton>}
                        
                    </div>

                </div>
            ),
        },
    ];

    const searchkeyword = () => {
        setFullTextSearch(searchQuery.toLowerCase());
    }

    const rows = useMemo(() => {
        let data = reports?.data.map((item) => {
            return {
                id: item.id,
                report_label: item.name,
                reportTypeName: item.reportTypeName,
                report_type: `${item.reportTypeName} \n ${item.latencyWarn ? "Warnings: " + item.latencyWarn + " sec" : ""} \n ${item.latencyFail ? "Failures: " + item.latencyFail + " sec" : ""} `,
                test_case_group: item.testCaseGroupName,
                time_frame: item.timeframeName,
                start_date: item.timeframeBegin,
                end_date: item.timeframeEnd,
                last_modified: formatDateTime(item.lastModifiedTime),
                startDate: new Date(item.timeframeBegin),
                endDate: new Date(item.timeframeEnd),

            }
        });

        if (fullTextSearch) {
            data = data.filter(d => d.id.toString().toLowerCase().indexOf(fullTextSearch) !== -1 ||
                d.report_label.toLowerCase().indexOf(fullTextSearch) !== -1);
        }

        if (filterOption?.reportType?.length) {
            data = data.filter(d => filterOption?.reportType.includes(d.reportTypeName));
        }

        if (filterOption?.groupName?.length) {
            data = data.filter(d => filterOption?.groupName.includes(d.test_case_group));
        }

        if (filterOption?.startDate) {
            data = data.filter(d => d.startDate >= new Date(filterOption?.startDate));
        }

        if (filterOption?.endDate) {
            data = data.filter(d => d.endDate <= new Date(filterOption?.endDate));
        }

        if (filterOption?.timeframe?.length) {
            data = data.filter(d => filterOption?.timeframe.includes(d.time_frame));
        }

        return data;

    }, [reports, fullTextSearch, filterOption]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.toolbar}>
                <TextField
                    fullWidth
                    placeholder={`${t("searchAmong")} ${rows?.length} ${t("reports.0")}`}
                    size='small'
                    type='search'
                    onChange={event => searchHandler(event.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>
                            <SvgIcon fontSize='small' color='action'>
                                <SearchIcon size={20} />
                            </SvgIcon>
                        </InputAdornment>,
                        endAdornment: <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={refresh} width="40px" alt="pic" className="pointer" onClick={searchkeyword}
                            />
                            <img src={filterIcon} style={{ width: '16px', cursor: "pointer" }} onClick={() => setFilterOpen(true)} />
                            <Filter isOpen={filterOpen} onClose={filterHandleClose} setSelected={setFilterOption}
                            />
                        </div>
                    }}

                />
                {writeAccess && <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#1f9997",
                        fontSize: "10px",
                        width: "90px",
                        borderRadius: "25px",
                        height: "40px",
                        marginLeft: "10px",
                        boxShadow: '0px 8px 16px #3F929E4D',
                        cursor: 'pointer',
                    }}
                    onClick={() => add()}
                >
                    <span style={{ fontSize: "16px" }}>+</span>&nbsp;<span>{t("new")}</span>
                </Button>}

            </div>
            <Box style={{ height: "550px", maxWidth: "100%" }}

            >
                {rows && rows.length > 0 ? <DataGrid rows={rows} columns={columns} checkboxSelection
                    rowHeight={30}
                    selectionModel={selected}
                    onSelectionModelChange={(newSelection) => setSelected(newSelection)}
                    apiRef={apiRef}
                    sortingOrder={['desc', 'asc']}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pageSize={rowPerPage}
                    page={page - 1}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                    }}
                    getCellClassName={(params) => {
                        if (params.field === "__check__" && selected.indexOf(params.id) <= -1) {
                            return 'unchecked'
                        }
                    }}
                /> : null}

                <div
                    style={{
                        position: "absolute",
                        left: "400px",
                        bottom: "25px",
                        fontSize: "14px",
                        width: "150px",
                        borderRadius: "20px",
                        color: "#CB1919",
                        display: deleteButton ? "none" : "flex",
                        alignItems: "center",
                        cursor: deleteButton ? "" : "pointer"
                    }}
                    onClick={handleDelete}
                >
                    <img src={trashCan} width="24px" alt="pic" />
                    Delete Selected
                </div>
                <ResponsiveDialog isOpen={open} openHash={hash}
                    statement={selected.length > 1 ? "these reports" : "this report"}
                    title="Delete"
                    ButtonDelete={() => {
                        if (selected.length > 0) {
                            deleteReports(selected)(dispatch)
                            setSelected([])
                        } else {
                            deleteReports(deleteId)(dispatch)
                        }
                    }
                    }

                />
            </Box>
        </div>
    );
}
