import React, { useMemo } from "react";
import { CssBaseline, Button, Box, IconButton, Typography, Chip, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ContentHeader from "../../mui/ContentHeader";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentGroupEdit,
  deleteGroup,
  getIvrs,
  getSettingsReports,
  ShowGlobalErrorAlert,
  toggleGlobalAlert,
  getDistributionTypes,
  isUserReadonly
} from "../../../actions";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import ResponsiveDialog from "../../mui/ResponsiveDialog";
import trashCan from "../../../images/Delete Action Icon Component.svg";
import edit from "../../../images/Edit Action Icon Component.svg";
import CustomFooter from "../../mui/CustomFooter";
import Modal from "../../mui/Modal";
import UpsertTestCaseGroup from "./Edit";
import { useHistory } from "react-router-dom";
import FilterComponent from "../../mui/FilterComponent";
import { formatDateTime } from "../../../helper/date";
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import { CHANNELS } from "../../../helper/constants";


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    marginBottom: theme.spacing(2),
    "& fieldset": {
      borderRadius: 35,
      borderColor: "#EEEEEE",
    },

    "& input": {
      fontSize: "1rem",
      lineHeight: "19px",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },

    display: "flex",
    alignItems: "center",
  },
  content: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const TestCaseGroupList = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [hash, setHash] = useState("init");
  const [hashEdit, setHashEdit] = useState("init");
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");
  const [deleteButton, setDeleteButton] = useState(true);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = React.useState([]);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);
  const reportData = useSelector(
    (state) => state.reportReducer.getSettingsReports
  );
  const distributionTypes = useSelector(state => state.reportReducer.distributionTypes);
  const [fullTextSearch, setFullTextSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [closeFilter, setCloseFilter] = useState(false);
  const [clearInput, setClearInput] = useState("");
  const writeAccess = !isUserReadonly();

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const {t} = useTranslation();
  
  const ReportTypes = {
		ALERT: 1,
		TEST_CASE_GROUP: 2,
		DISTRIBUTION: 3,
		USER: 4,
	};
  
  const SETTINGS_REPORT = [
		{
			title: t("alertSettings"), confirm: 'alert setting', id: ReportTypes.ALERT, url:'settings/alert-settings',columns: [
				{ field: "id", headerName: "ID", width: "90",  hide: true },
				{
					field: "name",
					headerName: t("statusAlertLabel.0"),
					
					flex: 1,
					
				},
				{
					field: "testCaseGroupName",
					headerName: t("testCaseGroup.1"),
					flex: 1,
					
					
				}, {
					field: "distributionName",
					headerName: t("contactGroup.0"),
					flex: 1,
				   
					
				},
				{
					field: "active",
					headerName: "NOTIFICATION",
					flex: 1,
									
				},
			],
		},
		{
			title: t("reportTypes.1"), id: ReportTypes.TEST_CASE_GROUP,
			confirm: 'test case group', url:'settings/test-case-group',
			columns: [
				{ field: "id", headerName: "ID", width: 90, disableColumnMenu: true },
				{
					field: "name",
					headerName: t("testCaseGroupLabel.0"),
					
					flex: 1,
					
				},
        {
					field: "channel",
					headerName: t("Channel"),
					flex: 1,
          renderCell: params => (
						<span>
							{params.row.channel === CHANNELS.VOICE ? 'Voice':'Web'}
						</span>
					)
					
				},
				{
					field: "caseCount",
					headerName: t("numberOfTestCases"),
					flex: 1,
					
					renderCell: params => (
						<span className="underscore1">
							{`${params.row.caseCount} from ${params.row.ivrCount} Applications`}
						</span>
					)
				},
			],
		},
		{
			title: t("reportTypes.2"), id: ReportTypes.DISTRIBUTION,
			confirm: 'distribution list', url:'settings/distribution-list',
			columns: [
				{ field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true, },
				{
					field: "name",
					headerName: t("distributionListLabel.0"),
					flex: 1,
					
				},
				{
					field: "label",
					headerName: t("label.0"),
					flex: 1,
					
					sortable: false,
					
					renderCell: params => (
						<>
							{params.row.label.map((l, index) => (
								<div key={`${index}-${l}`} className="db pb-30">{l}</div>
							))}
						</>

					)
				},
				{
					field: "type",
					headerName: "TYPE",
					flex: 1,
				   
					
					sortable: false,
					renderCell: params => (
						<>
							{params.row.type.map((l, index) => (
					
								<div key={`${index}-${l}`} className="db pb-30">{l}</div>
							))}
						</>

					)
				},
				{
					field: "email",
					headerName: t("emailNumber"),
					flex: 1,
					sortable: false,
									
					renderCell: params => (
						<>
							{params.row.email.map(l => (
								<div key={l} className="db pb-30">{l}</div>
							))}
						</>

					)
				},
				{
					field: "lastModifiedTime",
					headerName: t("lastModified"),
					flex: 1,
					
				},
			],
		},
		{
			title: t("reportTypes.3"), id: ReportTypes.USER, confirm: 'user', url:'settings/admin-settings', columns: [

				{ field: "id", headerName: "ID", width: 90, disableColumnMenu: true, hide: true, },
				{
					field: "firstName",
					headerName: t("firstName.2"),
					
					flex: 1,
					
				},
				{
					field: "lastName",
					headerName: t("lastName.2"),
					flex: 1,
					
				},
				{
					field: "username",
					headerName: t("username.2"),
					flex: 1,
					
				},
				{
					field: "roles",
					headerName: t("permissions.1"),
				   
					flex: 1,
					
					
				},
				{
					field: "testCaseGroups",
					headerName: t("testCaseGroups"),
				   
					flex: 1,
					sortable: false,
					
				   
				},
			],
		}
	];
  
  
  const handleDelete = (id) => {
    setOpen(true);
    setHash(Math.random().toString(16).substring(2, 10));
    setDeleteId(id);
  };

  const handleEdit = (id, report) => {
    if (+id === 0) {
      clearCurrentGroupEdit()(dispatch);
    }

    if (+report?.id === ReportTypes.DISTRIBUTION) {
      history.push(`/report/distribution/${id}`);
    } else if (+report?.id === ReportTypes.ALERT) {
      history.push(`/report/setting/${id}`);
    } else if (+report?.id === ReportTypes.USER) {
      history.push(`/users/${id}`);
    } else {
      setEditId(id);
      setEditOpen(true);
      setHashEdit(Math.random().toString(16).substring(2, 10));
    }
  };

  useEffect(() => {
    const report = SETTINGS_REPORT.find((s) => s.id === +id);
    setCurrentReport(report);
    if (report) {
      setTitle(report.title);

      if(writeAccess) {
        setColumns(
          report.columns.concat({
            field: "actions",
            headerName: "ACTIONS",
            resizable: false,
            disableClickEventBubbling: true,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            //   width: 100,
            minWidth: 130,
            maxWidth: 130,
            renderCell: (params) => (
              <div style={{ position: "relative", width: "100%" }}>
                <div>
                  <IconButton
                    component="span"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEdit(params.row.id, report);
                    }}
                  >
                    <img src={edit} alt="pic" width="30px" />
                  </IconButton>

                  <IconButton
                    color="error"
                    component="span"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete(params.row.id);
                    }}
                  >
                    <img src={trashCan} alt="pic" width="30px" />
                  </IconButton>
                </div>
              </div>
            ),
          })
        );
      } else {
        setColumns(report.columns);
      }
    }
    getDistributionTypes()(dispatch);
    getSettingsReports(+id)(dispatch);
    getIvrs()(dispatch);
    setPage(1);
    setSelected([]);
    setDeleteId("");
    setClearInput(`${new Date().getTime()}`);
  }, [id]);

  useEffect(() => {
    if (selected.length > 0) {
      setDeleteButton(false);
    } else {
      setDeleteButton(true);
    }

    return () => {
      setFullTextSearch("");
      ShowGlobalErrorAlert(null)(dispatch);
      toggleGlobalAlert("")(dispatch);
    };
  }, [selected]);

  const setSearchText = (input) => {
    setFullTextSearch(input);
  };

  const rows = useMemo(() => {
    // const types = ["Email", "SMS", "SMS", "Voice"];
    switch (+id) {
      case ReportTypes.TEST_CASE_GROUP:
        return reportData?.data?.filter(
          (d) => d.name?.toLowerCase().indexOf(fullTextSearch) !== -1
        );

      case ReportTypes.USER:
        let userData = (reportData?.data || []).filter(
          (d) =>
            d.firstName?.toLowerCase().indexOf(fullTextSearch) !== -1 ||
            d.lastName?.toLowerCase().indexOf(fullTextSearch) !== -1 ||
            d.username?.toLowerCase().indexOf(fullTextSearch) !== -1
        );

        if (filterStatus && filterStatus.length) {
          userData = userData.filter((d) => filterStatus.includes(d.roles[0]));
        }
        return userData.map((ud) => ({
          ...ud,
          roles: (ud.roles || [])
            .join(", ")
            .replace("ROLE_USER", "User")
            .replace("ROLE_ADMIN", "Administrator"),
          testCaseGroups:
            (ud.roles || []).join(", ") === "ROLE_ADMIN"
              ? "All"
              : (ud.testCaseGroups || []).length,
        }));

      case ReportTypes.ALERT:
        return reportData?.data
          ?.filter((d) => d.name?.toLowerCase().indexOf(fullTextSearch) !== -1)
          .map((d) => ({
            ...d,
            active: d.active ? "Active" : "Inactive",
          }));

      case ReportTypes.DISTRIBUTION:
        let data = reportData?.data
          ?.filter((d) => d.name?.toLowerCase().indexOf(fullTextSearch) !== -1)
          .map((d) => ({
            ...d,
            label: d.contacts?.map((c) => c.name),
            type: d.contacts?.map((c) => distributionTypes.find((type) => type.id === c.type).name),
            email: d.contacts?.map((c) => c.info),
            lastModifiedTime: formatDateTime(d.lastModifiedTime),
          }));

        if (filterStatus && filterStatus.length) {
          data = data.filter((d) =>
            d.type.some((t) => filterStatus.includes(t))
          );
        }
        return data;
    }
  }, [reportData, id, fullTextSearch, filterStatus]);

  const handleChangeType = (type) => {
    if (!selectedStatus.find((s) => s.id === type.id)) {
      setSelectedStatus([...selectedStatus, type]);
    } else {
      setSelectedStatus(selectedStatus.filter((s) => s.id !== type.id));
    }
  };

  const reset = () => {
    setSelectedStatus([]);
    setFilterStatus([]);
  };

  const applyFilters = () => {
    setFilterStatus(
      selectedStatus.map((s) => (+id === ReportTypes.USER ? s.id : s.name))
    );
    setCloseFilter(new Date().getTime());
  };

  const FilterList = () => {
    if (+id === ReportTypes.DISTRIBUTION) {
      return (
        <>
          <Typography variant="subtitle1">
            <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
          </Typography>
          <Box sx={{ display: "flex", marginTop: 1 }}>
            <Box>
              <Typography variant="subtitle1">
                <div style={{ fontSize: 14 }}>
                  {t("status")} &nbsp;&nbsp;
                  <span
                    style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}
                  >
                    {" "}
                    {selectedStatus.length > 0
                      ? `${selectedStatus.length} SELECTED`
                      : ""}{" "}
                  </span>
                </div>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  width: "400px",
                  marginTop: 1,
                }}
              >
                {[
                  { name: t("email"), id: 1 },
                  { name: "SMS", id: 2 },
                  { name: t("voice"), id: 4 },
                ].map((value, index) => (
                  <Chip
                    key={value.id}
                    label={value.name}
                    sx={{
                      fontWeight: "bold",
                      background: selectedStatus.find((d) => d.id === value.id)
                        ? "#3F929E1A 0% 0% no-repeat padding-box"
                        : "#FFFFFF 0% 0% no-repeat padding-box",
                      borderWidth: 1,
                      borderColor: "#DDDDDD",
                      borderStyle: "solid",
                      cursor: "pointer",
                    }}
                    onClick={() => handleChangeType(value)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            textAlign="left"
            sx={{
              width: "100%",
              display: "flex",
              position: "absolute",
              bottom: 0,
              left: 0,
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{ mt: 1, mb: 5, ml: 2 }}
              style={{
                borderRadius: 20,
                background: "#3F929E1A 0% 0% no-repeat padding-box",
                height: 38,
                width: "93px",
                color: "#1F9997",
              }}
              onClick={reset}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#1f9997",
                }}
              >
               {t("reset")}
              </Typography>
            </Button>

            <Button
              sx={{ mt: 1, mb: 5, mr: 2 }}
              style={{
                height: 40,
                borderRadius: 20,
                width: 135,
                background: "#1F9997 0% 0% no-repeat padding-box",
              }}
              onClick={applyFilters}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "white",
                }}
              >
                {t("applyFilters")}
              </Typography>
            </Button>
          </Box>
        </>
      );
    } else if (+id === ReportTypes.USER) {
      return (
        <>
          <Typography variant="subtitle1">
            <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
          </Typography>
          <Box sx={{ display: "flex", marginTop: 1 }}>
            <Box>
              <Typography variant="subtitle1">
                <div style={{ fontSize: 14 }}>
                  {t("permissionLevel")} &nbsp;&nbsp;
                  <span
                    style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}
                  >
                    {" "}
                    {selectedStatus.length > 0
                      ? `${selectedStatus.length} SELECTED`
                      : ""}{" "}
                  </span>
                </div>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  width: "400px",
                  marginTop: 1,
                }}
              >
                {[
                  { name: t("administrator"), id: "ROLE_ADMIN" },
                  { name: t("user.0"), id: "ROLE_USER" },
                ].map((value, index) => (
                  <Chip
                    key={value.id}
                    label={value.name}
                    sx={{
                      fontWeight: "bold",
                      background: selectedStatus.find((d) => d.id === value.id)
                        ? "#3F929E1A 0% 0% no-repeat padding-box"
                        : "#FFFFFF 0% 0% no-repeat padding-box",
                      borderWidth: 1,
                      borderColor: "#DDDDDD",
                      borderStyle: "solid",
                      cursor: "pointer",
                    }}
                    onClick={() => handleChangeType(value)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            textAlign="left"
            sx={{
              width: "100%",
              display: "flex",
              position: "absolute",
              bottom: 0,
              left: 0,
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{ mt: 1, mb: 5, ml: 2 }}
              style={{
                borderRadius: 20,
                background: "#3F929E1A 0% 0% no-repeat padding-box",
                height: 38,
                width: "93px",
                color: "#1F9997",
              }}
              onClick={reset}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#1f9997",
                }}
              >
                {t("reset")}
              </Typography>
            </Button>

            <Button
              sx={{ mt: 1, mb: 5, mr: 2 }}
              style={{
                height: 40,
                borderRadius: 20,
                width: 135,
                background: "#1F9997 0% 0% no-repeat padding-box",
              }}
              onClick={applyFilters}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "white",
                }}
              >
                {t("applyFilters")}
              </Typography>
            </Button>
          </Box>
        </>
      );
    }
      return null;
    };
    return (
        <div style={isMobile ? { paddingBottom: '65px', position: "absolute", top: "0px", left: "30px", right: '30px' } : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
            <ContentHeader title={`${t("settings.1")} / ${title}`} />
            <div className={classes.root}>
                <CssBaseline />
                <FilterComponent
                    filterComponent={(<FilterList />)}
                    clearInput={clearInput}
                    setSearchText={setSearchText}
                    closeDialog={closeFilter}
                    filter={+id !== 2 && +id !== 1} refresh={true} count={rows?.length} title={title}>
                      
                    {writeAccess && <Button
                        variant="contained"
                        onClick={() => handleEdit(0, currentReport)}
                        style={{
                            backgroundColor: "#1f9997",
                            fontSize: "10px",
                            width: "90px",
                            borderRadius: "25px",
                            height: "40px",
                            marginLeft: "10px",
                            boxShadow: '0px 8px 16px #3F929E4D',
                            cursor: 'pointer',
                        }}
                    >
                        <span style={{ fontSize: "16px" }}>+</span>&nbsp;<span>{t("new")}</span>
                    </Button>}

                </FilterComponent>
                {isMobile && (
                    <div className="db mb-2">
                        <Button
                            variant="contained"
                            onClick={() => handleEdit(0, currentReport)}
                            style={{
                                backgroundColor: "#1f9997",
                                fontSize: "10px",
                                width: "90px",
                                borderRadius: "25px",
                                height: "40px",
                                marginLeft: "10px",
                                boxShadow: '0px 8px 16px #3F929E4D',
                                cursor: 'pointer',
                            }}
                        >
                            <span style={{ fontSize: "16px" }}>+</span>&nbsp;<span>{t("new")}</span>
                        </Button>
                    </div>
                )}
                <Box style={{ height: "550px", maxWidth: "100%", width: '100%' }}>
                    {isMobile ? (rows || []).map((item, index) => (
                        <Accordion key={`row_${item.id}`} className={`row_${index % 2}`} elevation={0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="flex w-full">
                                    <Typography className=" semi-bold w-80" variant="caption"
                                    >{item.name}</Typography>
                                    <Typography className="semi-bold" variant="caption">{item.active}</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="db w-full">
                                    <Typography className="w-full inline-block semi-bold" variant="caption">{t("testCaseGroup.0")}: {item.testCaseGroupName}</Typography>
                                    <div className="mobile-view-toolbar-list">
                                        <IconButton component="span" size="small"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleEdit(item.id, currentReport)
                                            }}
                                        >
                                            <img src={edit} alt="pic" width="30px" />
                                        </IconButton>

                                        <IconButton color="error" component="span" size="small"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDelete(item.id)
                                            }}
                                        >
                                            <img src={trashCan} alt="pic" width="30px" />
                                        </IconButton>
                                    </div>
                                    <Typography className="w-full inline-block semi-bold" variant="caption">{t("contactGroup.2")}:{item.distributionName}</Typography>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )) : (
                        <>
                            {rows && rows.length > 0 ? <DataGrid rows={rows} columns={columns} checkboxSelection
                                //rowHeight={30}
                                selectionModel={selected}
                                onSelectionModelChange={(newSelection) => setSelected(newSelection)}
                                apiRef={apiRef}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                pageSize={rowPerPage}
                                page={page - 1}
                                sortingOrder={['desc', 'asc']}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'name', sort: 'asc' }],
                                    },
                                }}
                                components={{
                                    Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                                }}
                                getCellClassName={(params) => {
                                    if (params.field === "__check__" && selected.indexOf(params.id) <= -1) {
                                        return 'unchecked'
                                    } else if (["label", "type", "email"].includes(params.field)) {
                                        return "db";
                                    }

                                }}

                            /> : null}
                        </>
                    )}
                    <div
                        style={{
                            position: "absolute",
                            left: "400px",
                            bottom: "25px",
                            fontSize: "14px",
                            width: "150px",
                            borderRadius: "20px",
                            color: "#CB1919",
                            display: deleteButton ? "none" : "flex",
                            alignItems: "center",
                            cursor: deleteButton ? "" : "pointer"
                        }}
                        onClick={handleDelete}
                    >
                        <img src={trashCan} width="24px" alt="pic" />
                        Delete Selected
                    </div>
                    <ResponsiveDialog isOpen={open} openHash={hash}
                        statement={selected.length > 1 ? `these ${currentReport?.confirm}s` : `this ${currentReport?.confirm}`}
                        title="Delete"
                        ButtonDelete={() => {
                            if (selected.length > 0) {
                                deleteGroup(selected, +id)(dispatch)
                                setSelected([])
                            } else {
                                deleteGroup([deleteId], +id)(dispatch)
                            }

                        }
                        }

                    />
                    <Modal
                        isOpen={editOpen}
                        openHash={hashEdit}
                        body={<UpsertTestCaseGroup id={editId} cancel={() => setEditOpen(false)} />}
                    />
                </Box>
            </div>
        </div>
    );
};

export default TestCaseGroupList;
