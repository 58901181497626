import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../dashboard/Navbar";
import { Box, Grid, Typography, Chip, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import LeftBar from "../dashboard/LeftBar";
import { useParams } from "react-router-dom";
import ContentHeader from "../mui/ContentHeader";
import { formatDate, formatDateTime } from "../../helper/date";
import FilterComponent from "../mui/FilterComponent";
import { downloadFile, getAlertDetails } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import CustomFooter from "../mui/CustomFooter";
import downloadIcon from "../../images/Download Icon.svg";
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";


const AlertDetails = () => {
    const dispatch = useDispatch();
    const alertDetails = useSelector(state => state.reportReducer.alertDetails);
    const defaultPage = [0, 0, 1, 0]
    const apiRef = useGridApiRef();
    const [rowPerPage, setRowPerPage] = useState(10);
    const [page, setPage] = useState(1);
    let history = useHistory();
    const statuses = useSelector(state => state.reportReducer.alertStatuses);
    const { id } = useParams();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [closeFilter, setCloseFilter] = useState(false);
    const [fullTextSearch, setFullTextSearch] = useState('');
    const {t} = useTranslation();

    const handleChangeType = (type) => {
        if (!selectedStatus.find(s => s.id === type.id)) {
            setSelectedStatus([...selectedStatus, type])
        } else {
            setSelectedStatus(selectedStatus.filter(s => s.id !== type.id));
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const columns = [
        { field: "id", headerName: "ID", width: "90", disableColumnMenu: true, hide: true },
        {
            field: "runId",
            headerName: t("runId.1"),
            editable: false,
            flex: 1,
            width: '200',
            disableColumnMenu: true,
        },
        {
            field: "testCaseName",
            headerName: t("testCaseName.1"),
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            renderCell: params => (
                <div onClick={() => history.push(`/report/run/${params.row.id}`)}>{params.row.testCaseName}</div>
            )
        },
        {
            field: "runTime",
            headerName: t("dateTime.1"),
            editable: false,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "runDuration",
            headerName: t("duration.1"),
            editable: false,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "status",
            headerName: t("detailedStatus"),
            editable: false,
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "action",
            headerName: "ACTIONS",
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: params => (
                <img src={downloadIcon} onClick={() => downloadFile(params.row.runId, 'wcr')} alt="Download" className="pointer" />
            )
        },
    ];


    const rows = useMemo(() => (alertDetails?.testRuns || []).map(item => ({
        ...item,
        id: item.runId,
        status: statuses.find(s => s.id === +item.runStatusId)?.text,
        runTime: formatDateTime(item.runTime, 'yyyy/MM/dd HH:mm:ss'),
        runDuration: `${(+item.runDuration).toFixed(2)} sec`
    })).filter(c => filterStatus.length === 0 || filterStatus.includes(+c.runStatusId)).filter(c => c.testCaseName.indexOf(fullTextSearch) !== -1
        || c.runId.toString().indexOf(fullTextSearch) !== -1),
        [filterStatus, alertDetails, fullTextSearch])

    const Filter = () => {

        return (
            <>
                <Typography variant="subtitle1">
                    <div style={{ fontSize: 18, fontWeight: 500 }}>{t("filters")}</div>
                </Typography>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                    <Box>
                        <Typography variant="subtitle1">
                            <div style={{ fontSize: 14 }}>{t("status")} &nbsp;&nbsp;
                                <span style={{ color: "#1f9997", fontSize: 10, fontWeight: 600 }}> {selectedStatus.length > 0 ? `${selectedStatus.length} SELECTED` : ''} </span>
                            </div>

                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '400px', marginTop: 1 }}>
                            {[{ name: 'Failure', id: 7 }, { name: 'Pass', id: 3 }, { name: 'Warning', id: 4 }].map((value, index) => (
                                <Chip key={index} label={value.name}
                                    sx={{
                                        fontWeight: "bold",
                                        background: selectedStatus.find(d => d.id === value.id) ? "#3F929E1A 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderWidth: 1, borderColor: '#DDDDDD', borderStyle: 'solid',
                                        cursor: 'pointer'
                                    }} onClick={() => handleChangeType(value)} />
                            )
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box textAlign='left'
                    sx={{
                        width: "100%",
                        display: 'flex',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        sx={{ mt: 1, mb: 5, ml: 2 }}
                        style={{
                            borderRadius: 20,
                            background: '#3F929E1A 0% 0% no-repeat padding-box',
                            height: 38,
                            width: '93px',
                            color: '#1F9997',

                        }}
                        onClick={reset}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "#1f9997" }}>{t("reset")}</Typography>
                    </Button>

                    <Button
                        sx={{ mt: 1, mb: 5, mr: 2 }}
                        style={{
                            height: 40,
                            borderRadius: 20,
                            width: 135,
                            background: '#1F9997 0% 0% no-repeat padding-box',
                        }}
                        onClick={applyFilters}
                    >
                        <Typography style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", color: "white" }}>{t("applyFilters")}</Typography>
                    </Button>
                </Box>
            </>
        )
    }
    const reset = () => {
        setSelectedStatus([]);
        setFilterStatus([]);
    }

    const applyFilters = () => {
        setFilterStatus(selectedStatus.map(c => c.id));
        setCloseFilter(new Date().getTime());
    }

    const setSearchText = (input) => {
        setFullTextSearch(input);
    }

    useEffect(() => {
        if (id) {
            getAlertDetails(id)(dispatch);
        }
    }, [id]);

    return (
        <div>
            <Navbar />
            <Grid container>
                <Grid item>
                    <LeftBar page={defaultPage} />
                </Grid>
                <Grid item>
                    <div style={isMobile ? { position: "absolute", top: "0px", left: "20px", right: '10px' } : { position: "absolute", top: "0px", left: "130px", width: `calc(100% - 200px)` }}>
                        <ContentHeader title={t("alertsAlertDetails")} />
                        <Box textAlign='left'
                            sx={{
                                minWidth: isMobile ? "100%" : "500px",
                                borderColor: 'lightgrey',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: '#1F9997',
                                marginBottom: '5px',
                                verticalAlign: 'middle'
                            }}
                        >
                            <Typography type="body2" className="edit-title">
                                {t("alertDetails")}
                            </Typography>

                        </Box>
                        <Box sx={{ mt: 5, ml: 3 }}>
                            <Grid container className={isMobile ? "mobile-alert-settings" : ''}>
                                <Grid xs={isMobile ? 12 : 4}>
                                    <Typography variant="h7">{t("testCaseID")}:</Typography>
                                    <Typography variant="h8">#{alertDetails?.testCaseId}</Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : 8}>
                                    <Typography variant="h7">{t("testCaseName.0")}:</Typography>
                                    <Typography variant="h8">{alertDetails?.testCaseName}</Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : 4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("noOfTestRuns")}:</Typography>
                                    <Typography variant="h8">{alertDetails?.testRunCount}</Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : 4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("from")}:</Typography>
                                    <Typography variant="h8">{formatDate(alertDetails?.testRuns[0]?.runTime, 'yyyy/MM/dd')}</Typography>
                                    <Typography variant="h7" sx={{ ml: 1 }}>{t("to")}:</Typography>
                                    <Typography variant="h8">{formatDate(alertDetails?.testRuns[alertDetails?.testRuns.length - 1]?.runTime, 'yyyy/MM/dd')}</Typography>
                                </Grid>
                                <Grid xs={isMobile ? 12 : 4} sx={{ mt: 2 }}>
                                    <Typography variant="h7">{t("distributionList.0")}:</Typography>
                                    <Typography variant="h8">{alertDetails?.distributionName}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <FilterComponent filterComponent={(<Filter />)}
                                setSearchText={setSearchText}
                                closeDialog={closeFilter}
                                title={'test runs'} count={alertDetails?.testRuns.length} filter={true} refresh={true} />
                        </Box>
                        <Box sx={{ mt: 2, height: "550px" }}>
                            {isMobile ? rows.map((item, index) => (
                                <Accordion key={`row_${item.id}`} className={`row_${index % 2}`} elevation={0}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className="db w-full">
                                            <Typography className="underscore pointer w-full inline-block semi-bold" variant="caption"
                                            ><span onClick={() =>history.push(`/report/run/${item.id}`)}>{item.runId}-{item.testCaseName}</span></Typography>
                                            <Typography className="w-full inline-block semi-bold" variant="caption">{item.runTime}</Typography>
                                            <Typography className="w-full inline-block semi-bold" variant="caption"><span className={item.status}>{item.status}</span></Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="db w-full">
                                            <Typography className="w-full inline-block" variant="caption">{t("duration.0")}: {item.runDuration}</Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )) : (
                                <DataGrid rows={rows} columns={columns}
                                    apiRef={apiRef}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    pageSize={rowPerPage}
                                    page={page - 1}
                                    sortingOrder={['desc', 'asc']}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'id', sort: 'asc' }],
                                        },
                                    }}
                                    components={{
                                        Footer: () => CustomFooter(page, handlePageChange, rows, rowPerPage, setRowPerPage, setPage)
                                    }}
                                    getCellClassName={(params) => {
                                        if (params.field === "testCaseName") {
                                            return 'underscore pointer'
                                        } else if (params.field === "status") {
                                            return params.formattedValue;
                                        }

                                    }}

                                />
                            )}
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default AlertDetails;
