import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EmailIcon from "@mui/icons-material/Email";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LoadingButton from "@mui/lab/LoadingButton";
import lensLogo from "../../images/Lens Logo Teal E.svg"
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { login, ShowGlobalErrorAlert } from "../../actions";
import { useHistory } from "react-router-dom";
import Popup from "../../helper/Popup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SuccessAlert from "../mui/SuccessAlert";
import { useTranslation } from "react-i18next";
import banner from "../../images/banner.svg";
import lens from "../../images/lens_login.svg";
import lock from "../../images/lock.svg";
import user from "../../images/user.svg";
import tekvisionlogo from "../../images/tekvisionlogo.svg";
import { ssoApi } from "../../api/lensService";
import { getVoiceApi } from "../../api/config.service";

const useStyles = makeStyles((theme) => ({
    page: {
        opacity: 1,
    },
    textAlign: {
        position: "relative",
        top: "10px",
        color: "#1f9997",
    },
    logofl: {
        color: "#636364",
        fontSize: isMobile ? "32px" : "36px",
        opacity: 1,
        fontFamily: "Montserrat",
    },
    logo: {
        display: "flex",
        justifyContent: "space-between",
    },
    logoLg: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    formBox: {
        minWidth: "300px",
    },
    login: {
        color: "#707070",
        paddingTop: '1.5rem',
    },
}));

const schema = yup.object().shape({
    email: yup.string().required("Required").trim(),
    password: yup.string().required("Required").trim(),
});

const Login = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const loginResult = useSelector((state) => state.loginReducer.loginResult);
    const globalErrorAlert = useSelector(state => state.reportReducer.globalErrorAlert);
    const { t } = useTranslation();

    const [data, setData] = useState({
        email: '',
        password: '',
        checked: false,
    });

    const handleSSO = ()=> {
        document.location.href=`${getVoiceApi()?.replace('api/voice/v1','')}/?redirectUrl=${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`:``}/callback`;
    }


    

    const handleSaveSubmit = () => {
        setLoading(true);

        login({
            username: data.email,
            password: data.password,
            remember: data.checked,
        })(dispatch);
    };


    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        setLoading(false);
        if (loginResult && loginResult?.data && loginResult.data.username) {
            document.location.href = ("/dashboard");
        }
        if (loginResult && loginResult.message) {
            setOpen(true);
            setTimeout(function () {
                setOpen(false);
            }, 2500);
        }
    }, [loginResult]); // eslint-disable-line react-hooks/exhaustive-deps


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (globalErrorAlert) {
            setLoading(false);
            setShowErrorAlert(true);

            setTimeout(() => {
                ShowGlobalErrorAlert(null)(dispatch);
            }, 6000);
        } else {
            setShowErrorAlert(false);
        }

    }, [globalErrorAlert]);

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");

        if (loggedInUser) {
            const userData = JSON.parse(loggedInUser);
            if (userData) {
                setChecked(userData.remember);

                if (userData.remember) {
                    reset({
                        email: userData?.data?.username,
                        password: '',
                    })
                }
            }
        }
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = "#EFEFEF";
    }, []);



    return (
        <div className={classes.page}>
            {isMobile ? (
                <div className="mobile-login">
                    <div className="inner-login"></div>
                    <div className="mobile-login-form">
                        <img src={lens} height={'44px'} className="mt-20" />
                        <Typography variant="caption" className="db login-header-title small">Action Center</Typography>
                        <FormControl variant="standard" className="db pt-30">
                            <Input
                                onChange={(e: any) => setData({ ...data, email: e.target.value })}
                                id="input-with-icon-adornment"
                                size="small"
                                placeholder={t("username.0")}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={user} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <div className="db  mt-14">
                            <FormControl variant="standard" className="db">
                                <Input
                                    size="small"
                                    onChange={(e: any) => setData({ ...data, password: e.target.value })}
                                    placeholder={t("password.0")}
                                    type="password"
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={lock} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="db">
                            <FormControlLabel
                                className="f-12"
                                control={
                                    <Checkbox
                                        checked={data.checked}
                                        onChange={(e: any) => setData({ ...data, checked: e.target.checked })}
                                        inputProps={{ "aria-label": "controlled" }}
                                        sx={{
                                            color: "#1f9997",
                                            "&.Mui-checked": {
                                                color: "#1f9997",
                                            },
                                        }}
                                    />
                                }
                                label={t("rememberMe")}
                            />
                        </div>
                        <div className="db  mt-30 mb-30">
                            <Button className="login-button" onClick={handleSaveSubmit}> {t("login")}</Button>
                        </div>
                    </div>
                    <div className="tek-logo-mobile">
                        <img src={tekvisionlogo} width={'160px'} />
                    </div>
                </div>
            ) : (
                <>
                    <Header />
                    <div className="login-container v-top">
                        <div className="login-banner dib v-top">
                            <img src={banner} />
                            <img src={tekvisionlogo} className="tekvisionlogo" />
                        </div>
                        <div className="w-full main-login">
                            <img src={lens} />
                            <Typography variant="caption" className="db login-header-title pl-80">Action Center</Typography>
                            <FormControl variant="standard" className="db pt-69 pl-80">
                                <Input
                                    onChange={(e: any) => setData({ ...data, email: e.target.value })}
                                    id="input-with-icon-adornment"
                                    size="small"
                                    placeholder={t("username.0")}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={user} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            <div className="db  mt-14 pl-80">
                                <FormControl variant="standard" className="db">
                                    <Input
                                        size="small"
                                        onChange={(e: any) => setData({ ...data, password: e.target.value })}
                                        placeholder={t("password.0")}
                                        type="password"
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={lock} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="db pl-80 pt-20">
                                <FormControlLabel
                                    className="f-12"
                                    control={
                                        <Checkbox
                                            checked={data.checked}
                                            onChange={(e: any) => setData({ ...data, checked: e.target.checked })}
                                            inputProps={{ "aria-label": "controlled" }}
                                            sx={{
                                                color: "#1f9997",
                                                "&.Mui-checked": {
                                                    color: "#1f9997",
                                                },
                                            }}
                                        />
                                    }
                                    label={t("rememberMe")}
                                />
                            </div>
                            <div className="flex login-flex mt-59 pl-80">
                                <Button className="login-button" onClick={handleSaveSubmit}> {t("login")}</Button>
                                <Button className="login-button" onClick={handleSSO}> {t("SSO")}</Button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {loginResult && loginResult.message && (
                <Popup message={loginResult.message} open={open} />
            )}

            {showErrorAlert && (
                <SuccessAlert width={isMobile ? "95%": "80%"} s
                    etOpenAlert={setShowErrorAlert}
                    top="10px"
                    openAlert={showErrorAlert}
                    //openAlert={true}
                    statement={globalErrorAlert || ''} fail="error" />
            )}
        </div>
    );
};

export default Login;
