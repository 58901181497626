import { BrowserRouter, Route, Switch } from "react-router-dom";
import { theme } from "./theme";
import Login from "./components/login/Login";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import Dashboard from "./components/dashboard/Dashboard";
import Report from "./components/report/Report";
import Alert from "./components/alert/Alert";
import Setting from "./components/setting/Setting";
import ReportEdit from "./components/report/reportEdit/ReportEdit";
import ReportAdd from "./components/report/reportAdd/ReportAdd";
import ReportView from "./components/report/reportView/ReportView";
import TestResult from "./components/report/testResultView/TestResult";
import TestCaseGroupReport from "./components/report/test_case_group/Test-Case-Group";
import UpsertDistribution from "./components/report/distribution/Edit";
import AlertSetting from "./components/report/alert-setting/Alert-Setting";
import UpsertUser from "./components/users/Upsert-User";
import DrillDownReport from "./components/report/drill-down/View-Drill-Down";
import TestRunResult from "./components/report/drill-down/Test-Run";
import AlertDetails from "./components/alert/Alert-Details";
import Latency from "./components/report/drill-down/Latency";
import MosScore from "./components/report/drill-down/Mos-Score";
import { isMobile } from 'react-device-detect';
import { Grid } from "@mui/material";
import dashboard from "./images/nav-dashboard.svg";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getUserId} from './helper/user';
import { Telco } from "./components/dashboard/Telco";
import { useEffect } from "react";
import { getACApi, STORAGE_KEY } from "./api/config.service";
import { acApi } from "./api/lensService";
import { CallBack } from "./components/login/callback";
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'

const GenericNotFound = () => {
    return (
        <div id="wrapper">
            <img src="https://i.imgur.com/qIufhof.png" width="500px" alt="pic" />
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div>
    )
}

const App = () => {

    const handleOnIdle = () => {
        localStorage.clear();
        window.location.href = '/';
      };

      useIdleTimer({
        timeout: 1000 * 60 * 15, // 15 minutes
        onIdle: handleOnIdle,
        debounce: 500,
        events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'],
      });

    const goto = (url) => {
        document.location.href = url;
    }

    const getClassName = (url) => {
        const ref = document.location.pathname;
        if (ref.indexOf(url) !== -1) {
            return 'selected-nav-bar';
        }
        return ''
    }

    const getColor = (url) => {
        const ref = document.location.pathname;
        if (ref.indexOf(url) !== -1) {
            return '#1F9997';
        }
        return '#000'
    }

    useEffect(() => {
        (async () => {
            if(!getACApi()) {
                const api = await acApi.get(`?ac=${window.location.hostname.replace('localhost','devac.tekvision.com')}`);
                localStorage.setItem(STORAGE_KEY, JSON.stringify(api?.data));
                window.location.reload();
                
                return;
              }
        

        })()
    }, []);      
    return (
        <ThemeProvider theme={theme}>
            {isMobile && document.location.pathname !=='/' && (
                <div className="mobile-footer-menu">
                    <div onClick={() => goto('/dashboard')} className={getClassName('/dashboard')}>
                        <SpeedIcon sx={{ color: getColor('/dashboard') }}/>
                    </div>
                    <div onClick={() => goto('/alerts')}  className={getClassName('/alerts')}>
                        <NotificationsNoneIcon sx={{ color: getColor('/alerts') }}/>
                    </div>
                    <div onClick={() => goto('/settings/alert-settings/1')}  className={getClassName('/settings/')}>
                        <SettingsIcon sx={{ color: getColor('/settings/') }}/>
                    </div>
                    <div onClick={() => goto(`/users/${getUserId()}`)}  className={getClassName('/users/')}>
                        <AccountCircleIcon sx={{ color: getColor('/users/') }}/>
                    </div>
                    <div onClick={() => goto('/')} >
                        <ExitToAppIcon />
                    </div>
                </div>
            )}
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/callback" exact component={CallBack} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/reports" exact component={Report} />
                    <Route path="/report/edit/:id" exact component={ReportEdit} />
                    <Route path="/report/view/:id" exact component={ReportView} />
                    <Route path="/report/view/testcase/:id" exact component={TestResult} />
                    <Route path="/report/add" exact component={ReportAdd} />
                    <Route path="/alerts" exact component={Alert} />
                    <Route path="/alert/:id" exact component={AlertDetails} />
                    <Route path="/setting" exact component={Setting} />
                    <Route path="/settings/test-case-group/:id" exact component={TestCaseGroupReport} />
                    <Route path="/settings/admin-settings/:id" exact component={TestCaseGroupReport} />
                    <Route path="/settings/distribution-list/:id" exact component={TestCaseGroupReport} />
                    <Route path="/settings/alert-settings/:id" exact component={TestCaseGroupReport} />
                    <Route path="/report/distribution/:id" exact component={UpsertDistribution} />
                    <Route path="/report/setting/:id" exact component={AlertSetting} />
                    <Route path="/report/drill/:id/:status/:start/:end/:reportId?/:latency?" exact component={DrillDownReport} />
                    <Route path="/report/run/:id/:latency?" exact component={TestRunResult} />
                    <Route path="/users/:id" exact component={UpsertUser} />
                    <Route path="/report/latency/:hour/:id/:reportId/:status?/:date?" exact component={Latency} />
                    <Route path="/report/mos/:reportId/:minMos/:hour/:date" exact component={MosScore} />
                    <Route path="/telco" exact component={Telco} />
                    <Route component={GenericNotFound} />
                </Switch>
            </BrowserRouter>

        </ThemeProvider>
    );
};

export default App;
