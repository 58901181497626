import { getVoiceApi } from "../api/config.service";
import lensService, { loginService } from "../api/lensService";
import { CHANNELS } from "../helper/constants";
import { currentDate } from "../helper/date";
import { ReportTypes } from "../helper/report-helper";

export const LensActions = {
    Login: "Login",
    GroupsList: "GroupsList",
    GroupData: "GroupData",
    GetReports: "GetReports",
    GetReportTypes: "GetReportTypes",
    CreateReport: "CreateReport",
    GetOneReport: "GetOneReport",
    GetMosReport: "GetMosReport",
    EditReport: "EditReport",
    GetReportTimeframe: "GetReportTimeframe",
    ViewReport: "ViewReport",
    ViewTestResult: "ViewTestResult",
    GetSettingsReports: "GetSettingsReports",
    ClearSettingsReports: "ClearSettingsReports",
    SetReport: 'SetReport',
    GetIvrs: "GetIvrs",
    SetActiveGroup: 'SetActiveGroup',
    SetDistribution: 'SetDistribution',
    SetTestCaseGroup: 'SetTestCaseGroup',
    SetAlertSetting: 'SetAlertSetting',
    SetDistributions: 'SetDistributions',
    SetAlertStatuses: 'SetAlertStatuses',
    GetTestRunDetails: 'GetTestRunDetails',
    GetRuns: 'GetRuns',
    SetAlerts: 'SetAlerts',
    SetAlertDetails: 'SetAlertDetails',
    ShowGlobalAlert: 'ShowGlobalAlert',
    SetLatency: 'SetLatency',
    SetDashboardData: 'SetDashboardData',
    SetChannelSelection: 'SetChannelSelection',
    SetCaseGroups: 'SetCaseGroups',
    SetLatencyTestRuns: 'SetLatencyTestRuns',
    SetUserInfo: 'SetUserInfo',
    SetDistributionTypes: 'SetDistributionTypes',
    ShowGlobalErrorAlert: 'ShowGlobalErrorAlert',
    SetChannels: 'SetChannels',
    SetMos: 'SetMos',
    SetMosRuns: 'SetMosRuns',
    CurrentPlayer: 'CurrentPlayer',
    CurrentPlayerStatus: 'CurrentPlayerStatus',
};

export const downloadVoice = async (url) => {
    try {

        const res = await lensService.get(`${getVoiceApi()}/api/trs/v1/getContent?attachmentUrl=${url}`, {
            responseType: 'blob',
        });

        const blob = new Blob([res.data], { type: "octet/stream" });

        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const d = window.open();
        a.href = window.URL.createObjectURL(blob);
        a.download = res.headers["content-disposition"]?.split(';')?.[1]?.split('=')?.[1];
        a.click();
        window.URL.revokeObjectURL(url);

        d.close();

        return blob;

    } catch { }

    return null;
}

export const downloadFile = async (id, url, download = true) => {
    try {

        const res = await lensService.get(`${getVoiceApi()}/api/trs/v1/${url}/${id}`, {
            responseType: 'blob',
        });

        const blob = new Blob([res.data], { type: "octet/stream" });

        if (download) {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const d = window.open();
            a.href = window.URL.createObjectURL(blob);
            a.download = res.headers["content-disposition"]?.split(';')?.[1]?.split('=')?.[1];
            a.click();
            window.URL.revokeObjectURL(url);

            d.close();
        }

        return blob;

    } catch { }

    return null;
    //window.open(`${getVoiceApi()}/api/trs/v1/${url}/${id}`);
}

export const confirmEmailPhone = (email, phone) => async (dispatch) => {
    try {
        const res = await lensService.post(`/api/trs/v1/distributions/testContact?email=${email}${phone ? `&sms=${phone}` : ''}`, {});
        return res?.data;
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }



}
export const isUserReadonly = () => {
    const loggedInUser = localStorage.getItem("user");
    
    if (loggedInUser) {
        const userData = JSON.parse(loggedInUser);
        if (userData) {
            return userData?.accessType !== 'read-write';
        }
    }

    return true;
}

export const login = (data) => async (dispatch) => {
    try {
        const res = await loginService.post(`/api/auth/signin`, data);
        localStorage.setItem('TOKEN', res.data.data.accessToken);

        if (res.data) {
            dispatch({ type: LensActions.Login, payload: res.data });
            localStorage.setItem("user", JSON.stringify({ ...res.data.data, remember: data.remember }));
        }

    } catch (e) {
        let payload = null;
        if (e?.response?.data?.data?.message) {
            payload =
                "Please check your credentials and try again, otherwise contact support";
        }
        if (e?.message && !e?.response) {
            payload =
                "Unable to access action centre host, please try again in a few minutes or contact support";
        }

        const error = {
            response: {
                data: {
                    data: {
                        messages: ['Please check your credentials and try again, otherwise contact support']
                    }
                }
            }
        }
        await dispatch(ShowGlobalErrorAlert(error))

    }
};

export const ShowGlobalErrorAlert = (alert) => async (dispatch) => {
    if (+alert?.response?.status === 401) {
        dispatch({ type: LensActions.ShowGlobalErrorAlert, payload: ['Access is denied!'] });

    } else {
        dispatch({ type: LensActions.ShowGlobalErrorAlert, payload: alert?.response?.data?.data?.messages });

    }
}


export const toggleGlobalAlert = (alert) => async (dispatch) => {
    dispatch({ type: LensActions.ShowGlobalAlert, payload: alert });
}


export const groupsList = () => async (dispatch) => {
    try {
        const res = await lensService.get(`/api/trs/v1/groups`);
        if (res.data) {
            dispatch({ type: LensActions.GroupsList, payload: res.data });
        }

        if (res.data?.data[1].id) {
            const startDate = currentDate()
            await dispatch(getGroupData({ id: res.data?.data[1].id, startDate: startDate }))
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getSyetemComponents = async () => {
    const res = await lensService.get(`/api/trs/v1/groups/health`);
    return res?.data?.data;
}

export const getDashboardData = (startDate) => async (dispatch) => {
    const level = "case"
    try {
        const res = await lensService.get(`/api/trs/v1/groups`);
        let originalData = [];

        if (res.data) {

            for (let i = 0; i < res.data.data.length; i++) {
                const metric = await lensService.get(`api/trs/v1/groups/${res.data.data[i].id}/metric?level=${level}&startDate=${startDate}`);
                originalData = originalData.concat(metric.data.data.map(m => ({
                    ...m,
                    groupId: res.data.data[i].id,
                    groupName: res.data.data[i].name,
                    channel: res.data.data[i].channel
                })));
            }
        }

        dispatch({ type: LensActions.SetDashboardData, payload: originalData });

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const SetChannelSelection = (selectedChannel) => async (dispatch) => {
    dispatch({ type: LensActions.SetChannelSelection, payload: selectedChannel });
}

export const getGroupData = ({ id, startDate }) => async (dispatch) => {
    const level = "case"
    try {
        const res = await lensService.get(`api/trs/v1/groups/${id}/metric?level=${level}&startDate=${startDate}`);
        if (res.data) {
            dispatch({ type: LensActions.GroupData, payload: res.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getReports = () => async (dispatch) => {
    try {
        const res = await lensService.get(`api/trs/v1/reports`);
        if (res.data) {
            dispatch({ type: LensActions.GetReports, payload: res.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const deleteGroup = (id, type) => async (dispatch) => {
    try {
        let res = null
        let endpoint = type === ReportTypes.TEST_CASE_GROUP ? 'groups' :
            (type === ReportTypes.ALERT ? 'alerts/settings' : 'distributions');

        if (type === ReportTypes.USER) {
            endpoint = 'users';
        }

        for (let i = 0; i < id.length; i++) {
            res = await lensService.delete(`api/trs/v1/${endpoint}/${id[i]}`);
            if (!res.data || !res.data.code) break
        }

        if (res.data) {
            await dispatch(getSettingsReports(type))
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const getGroupById = (id) => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/groups/${id}`);

        if (res.data) {
            dispatch({ type: LensActions.SetActiveGroup, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getChannels = () => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/groups/channels`);

        if (res.data) {
            dispatch({ type: LensActions.SetChannels, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getMos = (groupId, startDate, endDate) => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/groups/${groupId}/mos?startDate=${startDate}&endDate=${endDate}`);


        if (res.data) {
            dispatch({ type: LensActions.SetMos, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getMosRuns = (hour, reportId, minMos, date) => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/reports/${reportId}/testruns?hrSlot=${+hour}&startDate=${date}&endDate=${date}&minMos=${minMos}&maxMos=5`);

        if (res.data) {
            dispatch({ type: LensActions.SetMosRuns, payload: res.data.data });
        }
        const report = await lensService.get(`api/trs/v1/reports/${reportId}`);

        if (report.data) {
            dispatch({ type: LensActions.GetMosReport, payload: report.data });
        }


    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}




export const clearCurrentGroupEdit = () => async (dispatch) => {
    try {

        dispatch({ type: LensActions.SetActiveGroup, payload: null });

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getLatencyTestRuns = (hour, id, reportId, status, date) => async (dispatch) => {
    try {

        const url = +id === -1 ? `api/trs/v1/reports/${reportId}/testruns?hrSlot=${+hour}&perfStatus=${status || 'All'}&startDate=${date}&endDate=${date}` : `api/trs/v1/reports/${reportId}/testruns?latencyThres=${id}&hrSlot=${+hour}`;
        const res = await lensService.get(url);

        if (res.data) {
            dispatch({ type: LensActions.SetLatencyTestRuns, payload: res.data.data });
        }

        const report = await lensService.get(`api/trs/v1/reports/${reportId}`);

        if (report.data) {
            dispatch({ type: LensActions.GetOneReport, payload: report.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getAlertStatuses = () => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/alerts/allStatus`);

        if (res.data) {
            dispatch({ type: LensActions.SetAlertStatuses, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}
export const resetPassword = (data, id) => async (dispatch) => {
    try {
        await lensService.patch(`api/trs/v1/users/${id}/overridePassword`, data);
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const upsertUser = (data, id) => async (dispatch) => {
    try {
        const res = +id === 0 ? await lensService.post(`api/trs/v1/users`, data) :
            await lensService.put(`api/trs/v1/users/${id}`, data);

        if (res.data && res.data.code) {
            await dispatch(getSettingsReports(ReportTypes.USER))
        }

        return res?.data;
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
    return null;
}

export const getUserById = (id) => async (dispatch) => {

    if (+id === 0) {
        dispatch({ type: LensActions.SetUserInfo, payload: null });
        return;
    }
    const res = await lensService.get(`api/trs/v1/users/${id}`);

    if (res.data) {
        dispatch({ type: LensActions.SetUserInfo, payload: res.data.data });
    }
}

export const getSettingsReports = (id) => async (dispatch) => {
    try {
        dispatch({ type: LensActions.ClearSettingsReports, payload: [] });

        let endpoint = id === ReportTypes.TEST_CASE_GROUP ? 'groups' :
            (id === ReportTypes.ALERT ? 'alerts/settings' : 'distributions');

        if (id === ReportTypes.USER) {
            endpoint = 'users';
        }

        const res = await lensService.get(`api/trs/v1/${endpoint}`);
        if (res.data) {
            dispatch({ type: LensActions.GetSettingsReports, payload: res.data });

            if (id === ReportTypes.TEST_CASE_GROUP) {
                dispatch({ type: LensActions.SetTestCaseGroup, payload: res.data.data });
            } else if (id === ReportTypes.DISTRIBUTION) {
                dispatch({ type: LensActions.SetDistributions, payload: res.data.data });
            }
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const updateCaseGroups = (caseGroups) => (dispatch) => {
    dispatch({ type: LensActions.SetCaseGroups, payload: caseGroups });
}

export const getTestRunDetails = (id) => async (dispatch) => {
    try {
        await dispatch(getAlertStatuses());
        const res = await lensService.get(`api/trs/v1/testruns/${id}`);

        if (res.data) {
            dispatch({ type: LensActions.GetTestRunDetails, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const getRuns = (testCaseId, start, end, reportId, latencyThres) => async (dispatch) => {
    try {
        await dispatch(getAlertStatuses());

        if (reportId) {
            const res = await lensService.get(`api/trs/v1/reports/${reportId}/testruns?startDate=${start}&endDate=${end}&segmentId=${testCaseId}${(latencyThres ? '&latencyThres=' + latencyThres : '')}`);

            if (res.data) {
                dispatch({ type: LensActions.GetRuns, payload: res.data.data });
            }
        } else {
            const res = await lensService.get(`api/trs/v1/testcases/${testCaseId}/runs?startDate=${start}&endDate=${end}`);

            if (res.data) {
                dispatch({ type: LensActions.GetRuns, payload: res.data.data });
            }
        }


    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getIvrs = () => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/ivrs`);
        if (res.data) {
            dispatch({ type: LensActions.GetIvrs, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const deleteReports = (id) => async (dispatch) => {

    try {
        let res = null
        if (typeof id === "number") {
            res = await lensService.delete(`api/trs/v1/reports/${id}`);
        } else {
            for (let i = 0; i < id.length; i++) {
                res = await lensService.delete(`api/trs/v1/reports/${id[i]}`);
                if (!res.data || !res.data.code) break
            }
        }
        if (res.data && res.data.code) {
            await dispatch(getReports())
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const downloadCSV = (id) => async (dispatch) => {

    try {
        const res = await lensService.get(`api/trs/v1/reports/${id}/export`);

        return {
            data: res.data,
            file: res.headers['content-disposition'].replace('attachment; filename=', ''),
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getReportTypes = (channel) => async (dispatch) => {
    try {
        const res = await lensService.get(`api/trs/v1/reports/types?channel=${channel ? channel : CHANNELS.VOICE}`);
        if (res.data) {
            dispatch({ type: LensActions.GetReportTypes, payload: res.data });
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const createReport = (data) => async (dispatch) => {
    try {
        const res = await lensService.post(`api/trs/v1/reports`, data);
        if (res.data && res.data.code) {
            dispatch({ type: LensActions.CreateReport, payload: res.data });
            await dispatch(getReports())
            await dispatch(toggleGlobalAlert('New Report successfully created'));
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const getAlertSetting = (id) => async (dispatch) => {
    try {

        const res = await lensService.get(`api/trs/v1/alerts/settings/${id}`);

        if (res.data) {
            dispatch({ type: LensActions.SetAlertSetting, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const getAlerts = (page, pageSize) => async (dispatch) => {
    try {

        await dispatch(getAlertStatuses());
        const res = await lensService.get(`api/trs/v1/alerts/events?pageNo=${(page - 1)}&pageSize=${pageSize}`);

        if (res.data) {
            dispatch({ type: LensActions.SetAlerts, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const getAlertDetails = (id) => async (dispatch) => {
    try {

        await dispatch(getAlertStatuses());
        const res = await lensService.get(`api/trs/v1/alerts/events/${id}`);

        if (res.data) {
            dispatch({ type: LensActions.SetAlertDetails, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const getDistributionById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LensActions.SetDistribution, payload: null });
        const res = await lensService.get(`api/trs/v1/distributions/${id}`);

        if (res.data) {
            dispatch({ type: LensActions.SetDistribution, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getDistributionTypes = () => async (dispatch) => {
    try {
        const res = await lensService.get(`api/trs/v1/distributions/types`);

        if (res.data) {
            dispatch({ type: LensActions.SetDistributionTypes, payload: res.data.data });
        }

    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}



export const upsertAlertSettings = (data, id) => async (dispatch) => {
    try {
        const res = id === 0 ? await lensService.post(`api/trs/v1/alerts/settings`, data) :
            await lensService.put(`api/trs/v1/alerts/settings/${id}`, data);

        if (res.data && res.data.code) {
            await dispatch(getSettingsReports(ReportTypes.ALERT))
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const upsertDistribution = (data, id) => async (dispatch) => {
    try {
        const res = id === 0 ? await lensService.post(`api/trs/v1/distributions`, data) :
            await lensService.put(`api/trs/v1/distributions/${id}`, data);

        if (res.data && res.data.code) {
            await dispatch(getSettingsReports(ReportTypes.DISTRIBUTION))
            await dispatch(toggleGlobalAlert(` Distribution has been successfully ${id === 0 ? 'created' : 'updated'}`));

        }
        return res?.data;
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
    return null;
}

export const upsertTestCaseGroup = (data, id) => async (dispatch) => {
    try {
        const res = id === 0 ? await lensService.post(`api/trs/v1/groups`, data) :
            await lensService.put(`api/trs/v1/groups/${id}`, data);

        if (res.data && res.data.code) {
            await dispatch(getSettingsReports(ReportTypes.TEST_CASE_GROUP))
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getOneReport = ({ id, reportType, testGroup, selectedChannel }) => async (dispatch) => {
    try {
        const res = await lensService.get(`api/trs/v1/reports/${id}`);
        if (res.data && res.data.code) {
            dispatch({ type: LensActions.GetOneReport, payload: res.data });
            if (!reportType) await dispatch(getReportTypes(selectedChannel))
            if (!testGroup) await dispatch(groupsList())
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const editReport = (data, id) => async (dispatch) => {
    try {
        const res = await lensService.put(`api/trs/v1/reports/${id}`, data);
        if (res.data && res.data.code) {
            dispatch({ type: LensActions.EditReport, payload: res.data });
            await dispatch(getReports());
            await dispatch(toggleGlobalAlert('Edit Report successfully.'));
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getTimeframe = () => async (dispatch) => {
    try {
        const res = await lensService.get(`api/trs/v1/reports/timeframes`);
        if (res.data) {
            dispatch({ type: LensActions.GetReportTimeframe, payload: res.data });
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const getLatency = () => async (dispatch) => {
    try {
        const latency = await lensService.get(`api/trs/v1/reports/latencythresholds`);

        if (latency.data) {
            dispatch({ type: LensActions.SetLatency, payload: latency.data.data });
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}

export const viewReport = ({ id }) => async (dispatch) => {
    try {

        dispatch({ type: LensActions.ViewReport, payload: [] });
        dispatch({ type: LensActions.SetReport, payload: null });

        await dispatch(getLatency());

        const res = await lensService.get(`api/trs/v1/reports/${id}`);
        let url = +res.data.data.reportTypeID === 6 ? `api/trs/v1/reports/${res.data.data.id}/latency`
            : `api/trs/v1/groups/${res.data.data.testCaseGroupID}/metric?level=${+res.data.data.reportTypeID === 5 ? 'segment' : 'case'}&startDate=${res.data.data.timeframeBegin}&endDate=${res.data.data.timeframeEnd}`;

        if (+res.data.data.reportTypeID === 2 && url.indexOf('endDate=') === -1) {
            url += `&endDate=${res.data.data.timeframeEnd}`;
        }
        const metrics = await lensService.get(url);


        if (metrics.data.data) {
            dispatch({ type: LensActions.ViewReport, payload: metrics.data.data });
        }

        if (res.data) {
            dispatch({ type: LensActions.SetReport, payload: res.data.data });
        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}


export const setCurrentPlayer = (id) => (dispatch) => {
    dispatch({ type: LensActions.CurrentPlayer, payload: id });
}

export const setPlayerStatus = (status) => (dispatch) => {
    dispatch({ type: LensActions.CurrentPlayerStatus, payload: status });
}

export const viewTestResult = ({ id }) => async (dispatch) => {
    try {
        // const res = await lensService.get(`api/trs/v1/reports/testResult/${id}`);
        const res = {
            "code": "success",
            "data": [
                {
                    "entityId": 1000011,
                    "entityName": "#16481-Manulife Group Benefit - 877-405-1992",
                    "date": "2022-01-01T23:59:34",
                    "duration": "90.88",
                    "status": "pass",
                },
                {
                    "entityId": 1000012,
                    "entityName": "#16481-Manulife Group Benefit - 877-405-1992",
                    "date": "2022-01-01T23:45:40",
                    "duration": "52.15",
                    "status": "warning",
                },
                {
                    "entityId": 1000013,
                    "entityName": "#16481-Manulife Group Benefit - 877-405-1992",
                    "date": "2022-01-01T13:00:40",
                    "duration": "5.15",
                    "status": "failed",
                },

            ]
        }



        if (res.data) {
            dispatch({ type: LensActions.ViewTestResult, payload: res.data });

        }
    } catch (e) {
        await dispatch(ShowGlobalErrorAlert(e))
    }
}
